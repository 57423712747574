.image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  -webkit-transition: opacity 1.5s ease-in-out;
  -moz-transition: opacity 1.5s ease-in-out;
  -o-transition: opacity 1.5s ease-in-out;
  transition: opacity 1.5s ease-in-out;
}

.image-container.hidden {
  opacity: 0;
}

img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 1.5s ease-in-out;
  -moz-transition: opacity 1.5s ease-in-out;
  -o-transition: opacity 1.5s ease-in-out;
  transition: opacity 1.5s ease-in-out;
}
