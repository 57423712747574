#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.slideshow-container {
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  justify-content: center;
  object-fit: contain;
  flex-direction: column;
}

/*.image-container {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  position: absolute;*/
/*  display: block;*/
/*  -webkit-transition: opacity 1.5s ease-in-out;*/
/*  -moz-transition: opacity 1.5s ease-in-out;*/
/*  -o-transition: opacity 1.5s ease-in-out;*/
/*  transition: opacity 1.5s ease-in-out;*/
/*}*/

/*img {*/
/*  object-fit: contain;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  -webkit-transition: opacity 1.5s ease-in-out;*/
/*  -moz-transition: opacity 1.5s ease-in-out;*/
/*  -o-transition: opacity 1.5s ease-in-out;*/
/*  transition: opacity 1.5s ease-in-out;*/
/*}*/

/*.current {*/
/*  opacity: 1;*/
/*}*/

/*.next {*/
/*  opacity: 0;*/
/*}*/
